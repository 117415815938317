// Place any jQuery/helper plugins in here.

var firebaseConfig = {
  apiKey: "AIzaSyAV6LwliXGK29mg-ZKHTFycpcrYdeJ29xs",
  authDomain: "the-competitors-pusher-plan.firebaseapp.com",
  databaseURL: "https://the-competitors-pusher-plan-default-rtdb.firebaseio.com",
  projectId: "the-competitors-pusher-plan",
  storageBucket: "the-competitors-pusher-plan.appspot.com",
  messagingSenderId: "288107618812",
  appId: "1:288107618812:web:571bf2cb89ea5035881279",
  measurementId: "G-ENP2CQ715W"
};
// Initialize Firebase
firebase.initializeApp(firebaseConfig);

const Ref = firebase.database().ref("js");
Ref.on('value', (snapshot) => {
  var options = {
    initial_zoom: 100,
    timenav_height_percentage: "61",
    start_at_end: true
  }
  var timeline_json = snapshot.val();
  var timeline = new TL.Timeline('timeline-embed', timeline_json, options);
});

setTimeout(function () {
  document.getElementById("splash").remove();
}, 1800);
